import http from "../http";
import Service from "../../Service";

/**
 * @type ProductService
 */
export default class ProductService extends Service {

    load(params) {
        return http.get(`admin/providers/products`, {
            headers: this.getHeaders(),
            params
        });
    }

    update(id, params) {
        return http.put(`admin/providers/products/${id}`, params, {
            headers: this.getHeaders()
        });
    }

    activate(id) {
        return http.put(`admin/providers/products/${id}/activate`, {}, {
            headers: this.getHeaders()
        });
    }

    deactivate(id) {
        return http.put(`admin/providers/products/${id}/deactivate`, {}, {
            headers: this.getHeaders()
        });
    }

    remove(id) {
        return http.delete(`admin/providers/products/${id}`, {
            headers: this.getHeaders()
        });
    }

    upload_cover(id, cover) {
        const data = new FormData();
        data.append("cover", cover);
        return http.post(`admin/providers/products/${id}/cover`, data, {
            headers: this.getHeaders()
        });
    }
}
